<template>
  <div>
    <template v-if="solution && workshop">
      <evaluation-widget
        :solution="solution"
        :workshop="workshop"
        :title="title"
      ></evaluation-widget>
    </template>
    <template v-if="!(solution && workshop)">
      {{ text }}
    </template>
  </div>
</template>

<script>
import { currentUser, loginAnonymous } from "../store";

import EvaluationWidget from "../components/EvaluationWidget";
export default {
  components: { EvaluationWidget },
  data() {
    return {
      solution: null,
      workshop: null,
      text: "Loading solution...",
      title: "",
    };
  },

  created: function () {
    if (this.$route.params.solution) {
      const load = () => {
        this.$store
          .dispatch("loadSolution", this.$route.params.solution)
          .then((solution) => {
            this.solution = solution;
            this.$store
              .dispatch("loadProjects", {
                _id: { $oid: solution.scenario.workshop_id },
              })
              .then((workshops) => {
                this.workshop = workshops[0];
                this.title = "Your solution for " + this.workshop.name;
              });
          })
          .catch((e) => {
            this.text = "Failed to load solution: " + e;
          });
      };

      if (!currentUser()) {
        loginAnonymous().then(load);
      } else {
        load();
      }
    } else {
      this.text = "No solution id provided";
    }
  },
};
</script>
